
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: rgb(41,41,41);
  background: linear-gradient(53deg, rgba(41,41,41,1) 0%, rgba(0,0,0,1) 41%, rgba(255,255,255,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  background-color: #292929;
  width:100%;
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 20%, #414141 74%);
}

html, body, #root, #root>div, .page-menu {
  height: 100%
}

@font-face {
  font-family: 'Lemonada';
  src: url('./fonts/Lemonada-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lemonada Bold';
  src: url('./fonts/Lemonada-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lemonada Light';
  src: url('./fonts/Lemonada-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Sofia Pro';
  src: url('./fonts/sofia-pro-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro Bold';
  src: url('./fonts/sofia-pro-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}