.general-container{
  max-width:1024px;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;

  .content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .img-container{
    height:280px;
    width:280px;
    border-radius: 50%;
    margin-top:30px;
    background-color: white;
    // padding:5px;
    border:5px solid white;
    transition: all .45s ease-in-out;
    overflow: hidden;
    cursor: pointer;
    &:hover{
      border-color: #ffc908;
      img{
        // transform:scale(1.3);
      }
    }
    img{
      transition: all .45s ease-in-out;
      height:100%;
      width:100%;
      border-radius: 50%;
    }
  }

  h1{
    font-size:34px;
    margin:20px 0 0;
    font-family: 'Lemonada Bold';
    color:white;
    text-align: center;
    line-height: 1;
  }

  h2{
    font-size:29px;
    margin:15px 10px 0;
    font-family: 'Lemonada';
    color:white;
    text-align: center;
    line-height: 1;
  }

  h6{
    font-size:16px;
    margin:0;
    font-family: 'Lemonada';
    color:white;
    text-align: center;
  }

  p{
    font-size:16px;
    color:white;
    max-width:570px;
    text-align: center;
    padding:0 20px;
  }

  .socials{
    margin-bottom:20px;
    ul{
      list-style: none;
      display:flex;
      padding:0;
      margin:0 auto;
      li{
        margin:0 10px;
        display: flex;
        align-items: center;
        transition: all .25s ease-in-out;
        &:hover{
          transform:scale(1.5);
          &:nth-of-type(1){
            svg{
              fill:#D44638;
            }
          }
          &:nth-of-type(2){
            svg{
              fill:#4267B2;
            }
          }
          &:nth-of-type(3){
            svg{
              fill:#0fefff;
            }
          }
          &:nth-of-type(4){
            svg{
              fill:#10a319;
            }
          }
        }
      }
    }
  }
  .yellow{
    color:#ffc908;
  }
}

.buttons{
  display: flex;
  // width:260px;
  margin-top:20px;
  width:100%;
  justify-content: space-between;

  button{
    background: none;
    outline:none;
    border:1px solid white;
    border-radius: 5px;
    // padding:5px 20px;
    color:white;
    // width:180px;
    margin:0 10px;
    font-size:20px;
    border:none;
    font-family: 'Lemonada Bold';
    cursor:pointer;
    transition: all .45s ease-in-out;
    &:hover{
      color:#ffc908;
    }
  }
}

//More
.more{
  height:100%;
  width:100%;
  z-index:99999;
  background:rgba(black,0.5);
  top:0;
  right:-100%; 
  position: fixed;
  transition: right 0.5s ease-out;
  
  
  .content{
    overflow: auto;
    background-color: #292929;
    height:100%;
    width:400px;
    float:right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom:20px;
    position: relative;

    @media screen and (max-width: 700px) {
      width:100%;
    }
  }

  .close{
    background:none;
    outline:none;
    font-family: 'Lemonada';
    border:none;
    font-weight: bold;
    position:absolute;
    top:5px;
    right:5px;
    color:white;
    font-size:26px;
    line-height: 1.2;
    cursor:pointer;
  }

  .top{
    margin-top:30px;
    width: 100%;
    padding: 0 10px;
  }

  .resume{
    margin-bottom:20px;
    .button{
      outline:none;
      border:none;
      cursor:pointer;
      background:none;
      border-radius: 4px;
      color:white;
      font-family: 'Lemonada';
      font-weight: bold;
      font-size:16px;
      padding:10px;
      transition: all .45s ease-in-out;
      text-decoration: none;
      &:hover{
        color:#ffc908;
        // background:#ffc908;
      }
    }
  }

  .about-me{
    margin: 20px 0 30px;
    ul{
      list-style: none;
      margin-left: 0;
      padding: 0;
      li{
        color:white;
        font-family: 'Lemonada Bold';
        a{
          color:white;
          transition: all .45s ease-in-out;
          &:hover{
            color:#ffc908;
          }
        }
        span{
          color:#ffc908;
        }
        &:last-child{
          font-size: 14px;
        }
      }
    }
  }

  .skills{
    margin-top: 20px;
    ul{
      // columns:2;
      list-style: none;
      padding: 0;
      li{
        color:white;
        font-family: 'Lemonada Bold';
        margin-bottom: 10px;
        .top-skill{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0px;
          p{
            margin: 0;
            padding: 0;
            &.small{
              color: rgba(255,255,255, 0.7); 
              font: 500 11px 'Lemonada Light';
            }
          }
        }

        .progress{
          height: 20px;
          .progress-bar{
            background-color: #ffc908;
            color: black;
          }
        }


        // &:before {
        //   content: '';
        //   display: inline-block;
        //   height: 12px;
        //   width: 12px;
        //   background-image: url('./images/tick.svg');
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   margin-right:0.5em;   
        // }
      }
    }
  }
  &.open{
    right:0;
  }
}
//END More

//Portofolio
.portofolio{
  height:100%;
  width:100%;
  z-index:99999;
  background:rgba(black,0.5);
  top:0;
  left:-100%; 
  position: fixed;
  transition: left 0.5s ease-out;

  .content{
    background-color: #292929;
    height:100%;
    width:400px;
    float:left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    @media screen and (max-width: 700px) {
      width:100%;
    }
  }

  .close{
    background:none;
    outline:none;
    font-family: 'Lemonada';
    border:none;
    font-weight: bold;
    position:absolute;
    top:5px;
    right:5px;
    color:white;
    font-size:26px;
    line-height: 1.2;
    cursor:pointer;
  }

  .close-video{
    background:none;
    outline:none;
    font-family: 'Lemonada';
    border:none;
    font-weight: bold;
    position:absolute;
    top:10px;
    right:10px;
    color:white;
    font-size:26px;
    line-height: 1.2;
    cursor:pointer;
  }

  .top{
    margin-top:30px;
  }
  &.open{
    left:0;
  }

  .projects{
    margin:30px 20px 20px;
    .first{
      height:250px;
      width:200px;
      cursor:pointer;
      background-image:url('./images/react-app.jpeg');
      background-size:cover;
      position:relative;
      .overlay{
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color:rgba(0,0,0,0.8);
        transition: all .45s ease-in-out;
        display:flex;
        justify-content:center;
        align-items: center;
        &:hover{
          opacity:0;
        }
        h3{
          color:white;
          opacity:0.8;
          font-size:28px;
          text-align:center;
        }
      }
    }
  }
  
  .video-container{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:rgba(0,0,0,0.5);
    display:flex;
    justify-content: center;
    align-items: center;
    &.close{
      display:none;
    }
    &.open{
      display:flex;
    }
    #video{
      outline:none;
      height:60%;
      width:60%;
      @media screen and (max-width: 700px) {
        height:200px;
        width:100%;
      }
    }
  }

}
//END Portofolio